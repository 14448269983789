import React, { useEffect, useState } from "react";
import api from "../api";
import { useAlert } from "react-alert";
import { Loader } from "./global/loader";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import userAction from "../redux/users/action";
import { Modal } from "react-bootstrap";

export const UserPost = () => {
  const alert = useAlert();
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const { setUser } = userAction;

  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [activeNav, setActiveNav] = useState("signals");
  const [isSubscription, setIsSubscription] = useState(false);

  const [showMore, setShowMore] = useState(false);
  const handleCloseMore = () => setShowMore(false);
  const handleShowMore = () => setShowMore(true);
  const [activeImg, setActiveImg] = useState("");

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      let checkSession = await checkUserSession();
      if (checkSession) {
        const postData = await api.postGet(payload);
        // console.log(postData);
        setData([...data, ...postData.post]);
        setnoOfPost(postData.count);
        setLimit(postData.limit);
        setLoader(false);
      } else {
        setLoader(false);
        alert.show("Session Expired");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleChange = (value) => {
    setPage(value);
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  const checkUserSession = async () => {
    let token = localStorage.getItem("token");
    // console.log(token);
    if (token) {
      let payload = { token: token };
      const registerationResponse = await api.verifySession(payload);
      // console.log(registerationResponse);
      if (registerationResponse?.token) {
        axios.defaults.headers.common["Authorization"] =
          registerationResponse?.token;
        return true;
      } else {
        dispatch(setUser({}));
        localStorage.removeItem("token");
        return false;
      }
    }
  };
  const checkSubs = async () => {
    setLoader(true);
    let checkSession = await checkUserSession();
    if (checkSession) {
      const res = await api.checkSubscription(userStore?.users?.user?._id);
      // console.log(res);

      if (res?.subscription) {
        let currentDate = new Date();
        let endDate = new Date(res?.subscription?.nextPayment);
        if (currentDate < endDate) {
          setIsSubscription(true);
        } else {
        }
      }
    } else {
      alert.show("Session Expired");
    }
  };

  useEffect(() => {
    if (userStore?.users?.user?._id) {
      checkSubs();
    }
  }, [userStore?.users]);

  const handleActiveImg = (link) => {
    setActiveImg(link);
    handleShowMore();
  };

  return (
    <>
      {loader ? (
        <div className="postBgLoad"></div>
      ) : (
        <div className="signalsPage">
          <div className="contact-widget-area pb-70">
            <div className="contact-widget-area-inner">
              <div className="container">
                {isSubscription ? (
                  <>
                    {/* <div className="section-title text-center mb-45 mt-4">
                    <span />
                    <h2 className="text-uppercase">waqar zaka updates</h2>
                  </div> */}
                    <div className="subscriptionNav">
                      <div
                        className={
                          activeNav == "signals" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("signals")}
                      >
                        Crypto Trades
                      </div>
                      <div
                        className={
                          activeNav == "commodities" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("commodities")}
                      >
                        Stock & Commodities Trades
                      </div>
                      <div
                        className={
                          activeNav == "market" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("market")}
                      >
                        Market Updates
                      </div>
                      {/* <div
                        className={
                          activeNav == "broadcast" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("broadcast")}
                      >
                        Broadcast
                      </div> */}
                      <div
                        className={
                          activeNav == "education" ? "link active" : "link"
                        }
                        onClick={() => setActiveNav("education")}
                      >
                        Education
                      </div>
                    </div>
                    {activeNav == "signals" && (
                      <>
                        <div className="userPostSec">
                          {data.length == 0 && !loader && (
                            <h3 className="text-center mt-4">
                              No Data Available
                            </h3>
                          )}

                          <div className="row">
                            {data.map((item, key) => (
                              <div className="col-md-6 mb-3" key={key}>
                                <div className="postCard">
                                  {item?.imageTitle && (
                                    <div
                                      className="imgDiv"
                                      onClick={() =>
                                        handleActiveImg(
                                          `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.imageTitle}.${item.imageFormat}`
                                        )
                                      }
                                    >
                                      <img
                                        src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.imageTitle}.${item.imageFormat}`}
                                        alt=""
                                      />
                                    </div>
                                  )}
                                  <div className="desc">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item?.description,
                                      }}
                                    />
                                    <div className="date">
                                      {" "}
                                      {moment(item?.createdAt).fromNow()}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        {limit * page < noOfPost && (
                          <div className="showMoreBtn text-center mt-5">
                            <button
                              className="themeBtn mx-auto"
                              onClick={() => handleChange(page + 1)}
                            >
                              Show More
                            </button>
                          </div>
                        )}
                      </>
                    )}
                    {activeNav == "commodities" && (
                      <StocksComp handleActiveImg={handleActiveImg} />
                    )}
                    {activeNav == "market" && <MarketComp />}
                    {/* {activeNav == "broadcast" && <BroadCastcomp />} */}
                    {activeNav == "education" && <Videocomp />}
                  </>
                ) : (
                  <h2 className="addWalletText">
                    This feature is only available for paid users.
                  </h2>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {loader && <Loader />}

      <Modal size="lg" show={showMore} onHide={handleCloseMore} centered>
        <Modal.Body>
          <div className="otpModalMain broadcastModal">
            <div className="closeIcon" onClick={handleCloseMore}>
              <i className="fa-solid fa-xmark" />
            </div>

            <div className="showMoreModalM">
              <div className="showMoreModal">
                <img className="w-100" src={activeImg} alt="" />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const BroadCastcomp = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      const postData = await api.broadcastGet(payload);
      // console.log(postData);
      setData([...data, ...postData.broadcast]);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleChange = (value) => {
    setPage(value);
  };
  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <div className="broadcastMain">
      {data.map((item, key) => (
        <div className="masgM" key={key}>
          <div className="date">{moment(item?.createdAt).fromNow()}</div>
          {item?.description && (
            <div className="msg">
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.description,
                }}
              />
            </div>
          )}
          {item?.voice && (
            <div className="audio">
              <audio controls controlsList="nodownload">
                <source
                  src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedVoice/${item.voice}`}
                />
                Audio element not supported by your browser.
              </audio>
            </div>
          )}
        </div>
      ))}
      {limit * page < noOfPost && (
        <div className="showMoreBtn text-center mt-5">
          <button className="themeBtn" onClick={() => handleChange(page + 1)}>
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

const Videocomp = () => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      const postData = await api.videoGet(payload);
      console.log(postData);
      setData([...data, ...postData.video]);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const handleChange = (value) => {
    setPage(value);
  };
  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <div className="broadcastMain">
      {data.map((item, key) => (
        <div className="masgM" key={key}>
          <div className="date">{moment(item?.createdAt).fromNow()}</div>
          {item?.video && (
            <div className="msg">
              <div
                dangerouslySetInnerHTML={{
                  __html: item?.video,
                }}
              />
              {item?.title && <div className="title">{item?.title}</div>}
            </div>
          )}
        </div>
      ))}
      {limit * page < noOfPost && (
        <div className="showMoreBtn text-center mt-5">
          <button className="themeBtn" onClick={() => handleChange(page + 1)}>
            Show More
          </button>
        </div>
      )}
    </div>
  );
};

const StocksComp = ({ handleActiveImg }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      const postData = await api.stocksGet(payload);
      console.log(postData);
      setData([...data, ...postData.post]);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleChange = (value) => {
    setPage(value);
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <>
      <div className="userPostSec">
        {data.length == 0 && !loader && (
          <h3 className="text-center mt-4">No Data Available</h3>
        )}

        <div className="row">
          {data.map((item, key) => (
            <div className="col-md-6 mb-3" key={key}>
              <div className="postCard">
                {item?.imageTitle && (
                  <div
                    className="imgDiv"
                    onClick={() =>
                      handleActiveImg(
                        `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.imageTitle}.${item.imageFormat}`
                      )
                    }
                  >
                    <img
                      src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.imageTitle}.${item.imageFormat}`}
                      alt=""
                    />
                  </div>
                )}
                <div className="desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.description,
                    }}
                  />
                  <div className="date">
                    {" "}
                    {moment(item?.createdAt).fromNow()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {limit * page < noOfPost && (
        <div className="showMoreBtn text-center mt-5">
          <button
            className="themeBtn mx-auto"
            onClick={() => handleChange(page + 1)}
          >
            Show More
          </button>
        </div>
      )}
    </>
  );
};
const MarketComp = ({ handleActiveImg }) => {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);

  const getPosts = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      const postData = await api.martketUpdateGet(payload);
      console.log(postData);
      setData([...data, ...postData.post]);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  const handleChange = (value) => {
    setPage(value);
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <>
      <div className="userPostSec">
        {data.length == 0 && !loader && (
          <h3 className="text-center mt-4">No Data Available</h3>
        )}

        <div className="row">
          {data.map((item, key) => (
            <div className="col-md-6 mb-3" key={key}>
              <div className="postCard">
                {item?.imageTitle && (
                  <div
                    className="imgDiv"
                    onClick={() =>
                      handleActiveImg(
                        `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.imageTitle}.${item.imageFormat}`
                      )
                    }
                  >
                    <img
                      src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${item.imageTitle}.${item.imageFormat}`}
                      alt=""
                    />
                  </div>
                )}
                <div className="py-2 iVideoPost">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.video,
                    }}
                  />
                </div>
                <div className="desc">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.description,
                    }}
                  />
                  <div className="date">
                    {" "}
                    {moment(item?.createdAt).fromNow()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {limit * page < noOfPost && (
        <div className="showMoreBtn text-center mt-5">
          <button
            className="themeBtn mx-auto"
            onClick={() => handleChange(page + 1)}
          >
            Show More
          </button>
        </div>
      )}
    </>
  );
};
