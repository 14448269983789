import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import axios from "axios";
import userAction from "../redux/users/action";
import { OtpModal } from "./otpModal";
import showPassImg from "../images/showPass.svg";
import hidePass from "../images/hidePass.svg";

export const Login = () => {
  const alert = useAlert();
  const navigate = useNavigate();
  const { setUser } = userAction;
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);

  const validators = () => {
    if (!data.email) {
      return "Email is required";
    }
    if (!data.password) {
      return "Password is required";
    }
    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const login = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const payload = {
        email: data?.email,
        password: data?.password,
      };
      const registerationResponse = await api.login(payload);
      if (!registerationResponse?.user?.emailVerified) {
        setShowModal(true);
        return;
      }

      axios.defaults.headers.common["Authorization"] =
        registerationResponse.token;

      const userId = registerationResponse?.user?._id;

      localStorage.setItem("token", registerationResponse.token);

      dispatch(setUser(registerationResponse));
      navigate("/");
      setLoader(false);
    } catch (e) {
      alert.show(e.message);
      // alert.show("Invalid Credentials or Account not verified");
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userStore?.users?.token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div className="contact-widget-area ">
        <div className="contact-widget-area-inner">
          <div className="">
            <div className="section-title text-center mb-45">
              <span />
              <h2 className="text-uppercase">Login</h2>
            </div>
            <div className="contact-form signup-form">
              <form
                noValidate
                id="contactForm"
                className="ng-invalid ng-dirty ng-touched"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        onChange={onTextChange}
                        type="text"
                        name="email"
                        id="email"
                        required
                        placeholder="Email"
                        className="form-control ng-dirty ng-invalid ng-touched"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group showPassInput">
                      <input
                        onChange={onTextChange}
                        type={showPass ? "text" : "password"}
                        name="password"
                        id="password"
                        required
                        placeholder="Password"
                        className="form-control ng-dirty ng-invalid ng-touched"
                      />
                      {showPass ? (
                        <img
                          onClick={() => setShowPass(false)}
                          src={showPassImg}
                          alt=""
                        />
                      ) : (
                        <img
                          onClick={() => setShowPass(true)}
                          src={hidePass}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12 text-right">
                    <div className="">
                      <Link className="forgetTxt" to={"/forget-password"}>
                        Forget Password
                      </Link>
                    </div>
                  </div>
                  <div className="col-12 my-3">
                    <div className="">
                      <Link className="createAccount ms-2" to={"/signup"}>
                        Create New Account?
                      </Link>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 text-center">
                    <button
                      onClick={login}
                      type="submit"
                      className="themeBtn w-100"
                    >
                      {" "}
                      Submit{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <OtpModal show={showModal} setShow={setShowModal} email={data.email} />
    </>
  );
};
