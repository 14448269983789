import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import copy from "copy-to-clipboard";

export const AnnualModal = ({ show, handleClose }) => {
  const [copyText, setCopyText] = useState("Copy to clipboard");

  const handleCopy = (text) => {
    copy(text);
    setCopyText("Copied");
    setTimeout(() => {
      setCopyText("Copy to clipboard");
    }, 3000);
  };
  return (
    <Modal show={show} onHide={handleClose} centered className="videoM">
      <Modal.Body>
        <div className="otpModalMain">
          <div className="closeIcon" onClick={handleClose}>
            <i className="fa-solid fa-xmark" />
          </div>

          {/* <h2 className="title color-dark mb-0">To Join Yearly Group.</h2> */}
          <h5 className="color-dark text-center mb-4 mt-2">
            Now you can pay in crypto.
          </h5>

          <div className="homeVideoM mb-3">
            <iframe
              className="w-100"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/cZheCh813xE?si=8PfVPJqiepBr6UQY&autoplay=1&mute=1"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
            {/* <video  className='w-100' controls>
                      <source src={homeVideo} type="video/webm" />
                      <source src={homeVideo} type="video/mp4" />
                      Sorry, your browser doesn't support videos.
                  </video> */}
          </div>

          <p className="teather">TETHER- USDT</p>
          <p className="teather">NETWORK- TRC20 ADDRESS:</p>
          <p className="teather">
            TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz
            <span
              className="copyToClip"
              onClick={() => handleCopy("TCck5FUoB6p34cwvfhkS93aj9nHcuNeDBz")}
            >
              <i className="fa-regular fa-copy ms-2 cursor-pointer"></i>
              <span>{copyText}</span>
            </span>
          </p>
          <p className="teather mb-3">
            PAYMENT: <span>1250$</span>
          </p>

          <div className="noteTitle">Note:</div>
          {/* <div className="closed">Membership is closed! </div>
              <div className="closed">Visit again </div> */}
          {/* <div className="note">
                Do not send any amount except yearly amount; If you send so, the amount will not be refunded and this account is just for yearly $1250 worth of usdt and again it is NOT Refundable. Send your CNIC front and back picture for KYC. If you are underage send your parents or school/college id card.
              </div> */}
          <div className="note">
            Do screen recording while doing transaction and Do not send any
            amount except yearly amount; If you send so, the amount will not be
            refunded and this account is just for yearly $1250 worth of usdt and
            again it is NOT Refundable. Send your CNIC front and back picture
            for KYC. If you are underage send your parents or school/college id
            card.
          </div>
          {/* <div className="desc1">Once you done payment Please Send the TX id and do screen record while doing transaction then Whatsapp Message on */}
          <div className="desc1">
            Once you done payment Please Send transaction successful screenshot,
            TX id and video of transaction then Whatsapp Message on
            <a href="https://wa.me/+971585036929">+971585036929 </a> send all
            details on WhatsApp no call will be entertained Office working
            hours.
          </div>
          <div className="desc1">01:00 PM to 09:00 PM</div>
          <div className="desc1">Monday to Friday Only</div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
