import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";
import api from "../../api";
import Logo from "../../images/logo-2.png";
import add from "../../images/add.png";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";

export function AddVideoLink({ getVideo, setShow }) {
  const adminStore = useSelector((state) => state.adminReducer);

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [inputData, setInputData] = useState({
    video: "",
  });
  const [isRecording, setIsRecording] = useState(false);

  const handleSubmit = async () => {
    try {
      if (inputData?.video) {
        setLoader(true);
        const formData = {
          video: inputData?.video,
          title: inputData?.title || "",
        };

        console.log(formData);

        const signalResponse = await api.videoAdd(formData);
        if (signalResponse?.success) {
          alert("Video added successfully");
          setInputData({
            title: "",
            video: "",
          });

          setLoader(false);
          setShow(false);
          getVideo();
        } else {
          setLoader(false);
          alert("Something went wrong");
        }
      } else {
        setLoader(false);
        alert("Please insert video iframe");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      alert("Something went wrong");
    }
  };

  const handleChange = (e) => {
    // console.log(e);
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="row mx-0 drivers-row">
        <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
          <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
            Add Video
          </h4>
        </div>
      </div>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="descMainPost">
            <div className="form-group mb-3">
              <input
                type="text"
                name="title"
                className="form-control"
                onChange={handleChange}
                placeholder="Add Title"
              />
            </div>
            <div className="form-group mb-3">
              <textarea
                name="video"
                id=""
                className="form-control"
                onChange={handleChange}
                placeholder="Add Video Iframe"
              ></textarea>
            </div>
          </div>

          <div className="sendMailBtn mt-3">
            <button
              onClick={handleSubmit}
              className="btn-dark"
              disabled={isRecording}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {loader && (
        <div className="seekoLoader">
          <div className="loader">
            <img src={Logo} alt="loader" />
          </div>
        </div>
      )}
    </>
  );
}
