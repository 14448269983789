import React from "react";
import logo from "../../images/homepage/logoNew.svg";

export const FooterNew = ({ show, setShow }) => {
  return (
    <footer className="footer-new">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-12 mb-4">
            <div className="">
              <div className="footer-logo">
                <a href="#">
                  <img src={logo} alt="Images" />
                </a>
              </div>
              <p className="desc">
                {" "}
                DISCLAIMER: We are an Educational Platform/Teachers, We dont ask
                for investments and we are not financial advisors. The Following
                groups are the only offical groups of Waqar Zaka.{" "}
              </p>
              <div className="social-link">
                <a href="https://www.facebook.com/waqarzaka/" target="_blank">
                  <i className="fa-brands fa-facebook-f" />
                </a>

                <a href="https://twitter.com/zakawaqar" target="_blank">
                  <i className="fa-brands fa-twitter" />
                </a>

                <a
                  href="https://www.instagram.com/waqarzaka/?hl=en"
                  target="_blank"
                >
                  <i className="fa-brands fa-instagram" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-6 mb-4">
            <div className=" ps-5">
              <h3>Resources</h3>
              <ul className="footer-list">
                <li>
                  <a href="#"> Home </a>
                </li>
                <li>
                  <a onClick={() => setShow(true)}> Courses </a>
                </li>
                <li>
                  <a href="#about"> About </a>
                </li>
                {/* <li>
                  <a href="#testimonial"> Testimonial </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-6 mb-4">
            <div className=" ps-5">
              <h3>Official Info</h3>
              <ul className="footer-contact">
                {/* <li><i className="fa-solid fa-location-dot" />
                <div className="content">
                  <h4>Location:</h4>
                  <span>DSO_IFZA IFZA Properties Dubai Silicon Oasis</span>
                </div>
              </li> */}
                <li>
                  <div className="content">
                    <h4>Email:</h4>
                    <a href="mailto:info@waqarzaka.net">info@waqarzaka.net</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="copy-right-text text-center">
              <p>
                {" "}
                Copyright © 2025 <b>Waqar Zaka</b> All Rights Reserved{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
