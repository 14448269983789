import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";

import "./App.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/flaticons.css";
import "./component/global/global.css";
import { navbarAnim } from "./assets/js/custom";

import AOS from "aos";
import "aos/dist/aos.css";
import { Navbar } from "./component/navbar";
import { Footer } from "./component/footer";
import { Home } from "./component/home";
import { Survey } from "./component/survey";
import { Signup } from "./component/signup";
import { Login } from "./component/login";
import api from "./api";
import userAction from "./redux/users/action";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "./component/global/sidebar/sidebar";
import { NavbarAdmin } from "./component/global/navbar/navbar";
import Users from "./component/global/users";
import { AdminLogin } from "./component/global/adminlogin";
import AllSurvey from "./component/global/survey";
import PromotionMail from "./component/global/promotionMail";
import AddPost from "./component/global/addPost";
import AllAdminPost from "./component/global/allPostAdmin";
import { UserPost } from "./component/userPost";
import axios from "axios";
import { DataInfo } from "./component/global/dataInfo";
import { ForgetPass } from "./component/forgetPass";
import { ResetPass } from "./component/resetPass";
import { Portfolio } from "./component/portfolio/portfolio";
import { ConnectPage } from "./component/portfolio/connectPage";
import { PortfolioDashboard } from "./component/portfolio/dashboard/dashboard";
import TupLokersMail from "./component/global/lockersMail";
import LockersList from "./component/global/lockersList";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./helpers/wagmi";
import WalletUsers from "./component/global/walletUsers";
import { NavbarNew } from "./component/HomeNew/navbar";
import { HomeNew } from "./component/HomeNew";
import { FooterNew } from "./component/HomeNew/footerNew";
import PaidUsers from "./component/global/paidUsersAdmin";
import { Checkout } from "./component/payment/checkout";
import { PayHistoryUser } from "./component/payment/payHistoryUser";
import { AuthNotLoginRoutes, AuthOnlyUserRoutes } from "./helpers/index";
import AdminBroadcast from "./component/global/adminBroadcast";
import { ContactSupport } from "./component/contactSupport";
import ContactSupAdmin from "./component/global/contactSupAdmin";
import AdminVideo from "./component/global/adminvideo";
import { AllAdminStocks } from "./component/global/adminStocks";
import { AllAdminMarket } from "./component/global/adminMarket";
import { CokieModal } from "./component/modals/cokieModal";

const queryClient = new QueryClient();

function App() {
  const { setUser } = userAction;
  const dispatch = useDispatch();
  const adminStore = useSelector((state) => state.adminReducer);
  const location = useLocation();
  const [show, setShow] = useState(false);

  useEffect(() => {
    navbarAnim();
    AOS.init();
    AOS.refresh();
    checkUserSession();
  }, []);

  const checkUserSession = async () => {
    let token = localStorage.getItem("token");
    // console.log(token);
    if (token) {
      let payload = { token: token };
      const registerationResponse = await api.verifySession(payload);
      // console.log(registerationResponse);
      if (registerationResponse?.token) {
        axios.defaults.headers.common["Authorization"] =
          registerationResponse?.token;
      } else {
        dispatch(setUser({}));
        localStorage.removeItem("token");
      }
    }
  };

  return (
    <div className="App">
      {adminStore?.admin?.admin?.email ? (
        <div id="wrapper">
          <Sidebar />
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <NavbarAdmin />
              <Routes>
                <Route
                  exact={true}
                  path="/admin/datainfo"
                  element={<DataInfo />}
                />
                <Route exact={true} path="/admin/users" element={<Users />} />
                <Route
                  exact={true}
                  path="/admin/wallet-users"
                  element={<WalletUsers />}
                />
                <Route
                  exact={true}
                  path="/admin/paid-users"
                  element={<PaidUsers />}
                />
                {/* <Route exact={true} path="/admin/survey" element={<AllSurvey />} /> */}
                <Route
                  exact={true}
                  path="/admin/sendmail"
                  element={<PromotionMail />}
                />
                {/* <Route exact={true} path="/admin/sendmail-tup-locker" element={<TupLokersMail />} /> */}
                {/* <Route exact={true} path="/admin/tup-locked" element={<LockersList />} /> */}
                <Route
                  exact={true}
                  path="/admin/addpost"
                  element={<AddPost />}
                />
                <Route
                  exact={true}
                  path="/admin/signals"
                  element={<AllAdminPost />}
                />
                <Route
                  exact={true}
                  path="/admin/broadcast"
                  element={<AdminBroadcast />}
                />
                <Route
                  exact={true}
                  path="/admin/video"
                  element={<AdminVideo />}
                />
                <Route
                  exact={true}
                  path="/admin/stocks"
                  element={<AllAdminStocks />}
                />
                <Route
                  exact={true}
                  path="/admin/market"
                  element={<AllAdminMarket />}
                />
                <Route
                  exact={true}
                  path="/admin/contact"
                  element={<ContactSupAdmin />}
                />
              </Routes>
            </div>
          </div>
        </div>
      ) : location.pathname == "/portfolio/connect" ||
        location.pathname.includes("/portfolio/dashboard") ||
        location.pathname.includes("/portfolio/tx") ? (
        <>
          <WagmiProvider config={config}>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route
                  exact={true}
                  path="/portfolio/connect"
                  element={<ConnectPage />}
                />
                <Route
                  exact={true}
                  path="/portfolio/*"
                  element={<PortfolioDashboard />}
                />
              </Routes>
            </QueryClientProvider>
          </WagmiProvider>
        </>
      ) : (
        <>
          <NavbarNew show={show} setShow={setShow} />
          <Routes>
            {/* <Route exact={true} path="/" element={<Home />} /> */}
            <Route exact={true} path="/" element={<HomeNew />} />
            {/* <Route exact={true} path="/survey" element={<Survey />} /> */}
            <Route element={<AuthOnlyUserRoutes />}>
              <Route exact={true} path="/dashboard" element={<UserPost />} />
            </Route>
            {/* <Route element={<AuthOnlyUserRoutes />}>
              <Route
                exact={true}
                path="/contact-support"
                element={<ContactSupport />}
              />
            </Route> */}
            <Route element={<AuthNotLoginRoutes />}>
              <Route exact={true} path="/signup" element={<Signup />} />
            </Route>
            <Route element={<AuthNotLoginRoutes />}>
              <Route exact={true} path="/login" element={<Login />} />
            </Route>
            <Route element={<AuthNotLoginRoutes />}>
              <Route
                exact={true}
                path="/forget-password"
                element={<ForgetPass />}
              />
            </Route>
            <Route element={<AuthNotLoginRoutes />}>
              <Route
                exact={true}
                path="/reset-password/:id"
                element={<ResetPass />}
              />
            </Route>
            <Route element={<AuthOnlyUserRoutes />}>
              <Route exact={true} path="/checkout" element={<Checkout />} />
            </Route>
            <Route element={<AuthOnlyUserRoutes />}>
              <Route
                exact={true}
                path="/subscription/history"
                element={<PayHistoryUser />}
              />
            </Route>
            <Route exact={true} path="/admin-login" element={<AdminLogin />} />
            <Route exact={true} path="/portfolio" element={<Portfolio />} />
          </Routes>
          <FooterNew show={show} setShow={setShow} />
          {/* {location.pathname == "/home" ? (
          ) : (
            <Footer show={show} setShow={setShow} />
          )} */}
        </>
      )}
      <CokieModal />
    </div>
  );
}

export default App;
