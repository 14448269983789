import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import Logo from "../../images/logo-2.png";
import { useSelector } from "react-redux";
import api from "../../api";
import { Pagination, Stack } from "@mui/material";
import { Loader } from "./loader";
import { Modal } from "react-bootstrap";
import { AddVideoLink } from "./addVideoLink";

function AdminVideo({ match }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // console.log(window.location);
  // console.log(match.url);
  const getVideo = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      const postData = await api.videoGetAdmin(payload);
      console.log(postData);
      setData(postData.video || []);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const deleteBroadcast = async (id) => {
    try {
      let payload = {
        id: id,
      };
      setLoader(true);
      const userData = await api.videoDelete(payload);
      console.log("deleted");
      // console.log(userData);
      getVideo();
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getVideo();
  }, [page]);

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col">
            <div className="row mx-0 drivers-row">
              <div className="col-12 d-flex justify-content-between pr-0 flex-wrap">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
                  Videos ({noOfPost})
                </h4>
                <button className="themeBtn" onClick={handleShow}>
                  Add Video
                </button>
              </div>
            </div>
            <Table
              data={data}
              setPage={setPage}
              page={page}
              noOfPost={noOfPost}
              limit={limit}
              deleteBroadcast={deleteBroadcast}
            />
          </div>
        </div>
      </div>
      {/* /.container-fluid */}
      {loader && <Loader />}
      <Modal show={show} size="lg" onHide={handleClose} centered className="">
        <Modal.Body>
          <div className="otpModalMain">
            <div className="closeIcon" onClick={handleClose}>
              <i className="fa-solid fa-xmark" />
            </div>
            <AddVideoLink getVideo={getVideo} setShow={setShow} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
const Table = ({ data, page, setPage, noOfPost, limit, deleteBroadcast }) => {
  // const authedUser = useSelector(s => s.authedUser.authedUser);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">
                    Title
                  </th>
                  <th scope="col" className="">
                    Video
                  </th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <span className="table-text-dark db">
                        {item?.title || "-"}
                      </span>
                    </td>
                    <td>
                      {/* <span className="table-text-dark db">{item?.video}</span> */}
                      <div className="iframeVideoAdmin">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.video,
                          }}
                        />
                      </div>
                    </td>

                    <td>
                      <div className="mr-5 w-40">
                        {/* <a href="#" className="mr-3"><img src={edit} alt="edit" /></a> */}
                        <a href="#" onClick={() => deleteBroadcast(item?._id)}>
                          <img src={Delete} alt="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(noOfPost / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminVideo;
