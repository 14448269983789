import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactQuill from "react-quill";
import api from "../../api";
import Logo from "../../images/logo-2.png";
import add from "../../images/add.png";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";

import MicRecorder from "mic-recorder-to-mp3";
import { Base64 } from "js-base64";
const Mp3Recorder = new MicRecorder({
  bitRate: 64,
  prefix: "data:audio/wav;base64,",
});

function AddBroadcast({ getBroadcast, setShow }) {
  const adminStore = useSelector((state) => state.adminReducer);

  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [inputData, setInputData] = useState({
    description: "",
    img: "",
    format: "",
  });
  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState("");
  const [blob, setBlob] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);

  const handleSubmit = async () => {
    try {
      if (inputData.description || blob?.size) {
        setLoader(true);
        const formData = new FormData();
        formData.append("_id", adminStore.admin.admin._id);
        formData.append("description", inputData?.description || "");
        if (blob?.size) {
          formData.append("voice", blob, "mp3.mp3");
        }
        console.log(blob);

        const signalResponse = await api.broadcastCreate(formData);
        if (signalResponse?.success) {
          alert("Broadcast created successfully");
          setInputData({
            description: "",
          });
          setBlobURL("");
          setBlob("");

          setLoader(false);
          setSelectedVoice(null);
          setShow(false);
          getBroadcast();
        } else {
          setLoader(false);
          alert("Something went wrong");
        }
      } else {
        setLoader(false);
        alert("Please insert description or voice");
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      alert("Something went wrong");
    }
  };

  var Editor = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const onFileChange = async (e) => {
    try {
      setLoader(true);

      const formData = new FormData();
      formData.append("image", e.target.files[0], e.target.files[0].name);
      const uploadRes = await api.uploadFile(formData);

      setSelectedVoice(
        `${process.env.REACT_APP_SERVER_BASE_URL}/uploadedVoice/voice_${uploadRes[0]}.${uploadRes[1]}`
      );
      setInputData({
        ...inputData,
        voiceTitle: uploadRes[0],
        voiceFormat: uploadRes[1],
      });
      setLoader(false);
    } catch (e) {
      alert("File upload failed");
      setLoader(false);
      console.log(e);
    }
  };

  const handleChange = (e) => {
    // console.log(e);
    setInputData({ ...inputData, description: e });
  };

  // broadcast
  const start = () => {
    if (isBlocked) {
      alert.show("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };
  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        const binaryString = btoa(blobURL);
        console.log(binaryString);
        console.log(blob);
        console.log(buffer);
        setIsRecording(false);
        setBlobURL(blobURL);
        setBlob(blob);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setIsBlocked(false);
      },
      () => {
        console.log("Permission Denied");
        setIsBlocked(true);
      }
    );
  }, []);

  return (
    <>
      <div className="row mx-0 drivers-row">
        <div className="col-xl-6 col-md-7  d-flex justify-content-between pr-0">
          <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
            Add Broadcast
          </h4>
        </div>
      </div>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          {/* <div className="form-group mb-3">
                                <input type="text" className='form-control' onChange={(e)=>setTitle(e.target.value)} placeholder='Title...' />
                              </div> */}
          <div className="descMainPost">
            <ReactQuill
              placeholder="Add description"
              modules={Editor}
              theme="snow"
              value={inputData.description}
              onChange={handleChange}
            />
          </div>
          <div className="voiceUploadRow">
            <audio src={blobURL} controls="controls" />
            {isRecording ? (
              <button onClick={stop} disabled={!isRecording}>
                <i className="fa-solid fa-microphone-lines-slash"></i>
              </button>
            ) : (
              <button onClick={start} disabled={isRecording}>
                <i className="fa-solid fa-microphone-lines"></i>
              </button>
            )}
          </div>{" "}
          <div className="sendMailBtn mt-3">
            <button
              onClick={handleSubmit}
              className="btn-dark"
              disabled={isRecording}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {loader && (
        <div className="seekoLoader">
          <div className="loader">
            <img src={Logo} alt="loader" />
          </div>
        </div>
      )}
    </>
  );
}

export default AddBroadcast;
