import axios from "./axios";
const api = {};

api.register = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/register`;

  return axios.post(url, body);
};

api.login = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/login`;
  return axios.post(url, body);
};
api.forgetPass = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/forget-password`;
  return axios.post(url, body);
};
api.resetPass = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/reset-password`;
  return axios.post(url, body);
};
api.adminLogin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/login`;
  return axios.post(url, body);
};
api.allUsers = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/users`;
  return axios.post(url, body);
};
api.allWalletUsers = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/wallet-users`;
  return axios.post(url, body);
};
api.getPaidUsers = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/paid-users`;
  return axios.post(url, body);
};
api.deleteUser = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/delete/user`;
  return axios.post(url, body);
};
api.allSurvey = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/survey`;
  return axios.post(url, body);
};
api.sendMailToAllUser = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/send-mail-to-all-user`;
  return axios.post(url, body);
};
api.sendMailToAllLockers = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/locked/sent-mail-all-lockers`;
  return axios.post(url, body);
};
api.sendPostMailToAllUser = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/send-post-mail-to-all-user`;
  return axios.post(url, body);
};
api.verifySession = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/check/session`;
  return axios.post(url, body);
};
api.survey = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/survey`;
  return axios.post(url, body);
};
api.userEdit = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/edit`;
  return axios.post(url, body);
};
api.verifyEmail = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/user/verifyOTP`;
  return axios.post(url, body);
};
api.uploadFile = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/file/upload`;
  return axios.post(url, body);
};
api.postCreate = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/post/new`;
  return axios.post(url, body);
};
api.postEdit = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/post/edit`;
  return axios.patch(url, body);
};
api.postDelete = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/post/delete`;
  return axios.delete(url, body);
};
api.postGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/post/get`;
  return axios.post(url, body);
};
api.postGetAdmin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/post/get`;
  return axios.post(url, body);
};
api.stocksCreate = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stocks/new`;
  return axios.post(url, body);
};
api.stocksEdit = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stocks/edit`;
  return axios.patch(url, body);
};
api.stocksDelete = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stocks/delete`;
  return axios.delete(url, body);
};
api.stocksGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/stocks/get`;
  return axios.post(url, body);
};
api.stocksGetAdmin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/stocks/get`;
  return axios.post(url, body);
};
api.martketUpdateCreate = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/market/new`;
  return axios.post(url, body);
};
api.martketUpdateEdit = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/market/edit`;
  return axios.patch(url, body);
};
api.martketUpdateDelete = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/market/delete`;
  return axios.delete(url, body);
};
api.martketUpdateGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/market/get`;
  return axios.post(url, body);
};
api.martketUpdateGetAdmin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/market/get`;
  return axios.post(url, body);
};
api.videoAdd = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/video/add`;
  return axios.post(url, body);
};
api.videoGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/video/get`;
  return axios.post(url, body);
};
api.videoGetAdmin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/video/get`;
  return axios.post(url, body);
};
api.videoDelete = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/video/delete`;
  return axios.delete(url, body);
};
api.broadcastCreate = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/broadcast/new`;
  return axios.post(url, body);
};
api.broadcastEdit = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/broadcast/edit`;
  return axios.patch(url, body);
};
api.broadcastDelete = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/broadcast/delete`;
  return axios.delete(url, body);
};
api.broadcastGetAdmin = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/broadcast/get`;
  return axios.post(url, body);
};
api.broadcastGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/broadcast/get`;
  return axios.post(url, body);
};
api.getDataInfo = function () {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/datainfo`;
  return axios.get(url);
};
api.getUserByMail = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/admin/get/userbymail`;
  return axios.post(url, body);
};
api.getLockerByMail = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/locked/get/email`;
  return axios.post(url, body);
};
api.getLocker = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/locked/get`;
  return axios.post(url, body);
};
api.addWallet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/wallet/add`;
  return axios.post(url, body);
};
api.deleteWallet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/wallet/delete`;
  return axios.post(url, body);
};
api.createPayment = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/create`;
  return axios.post(url, body);
};
api.getUserPayHistory = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/history`;
  return axios.post(url, body);
};
api.supportAdd = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/contact-support/add`;
  return axios.post(url, body);
};
api.supportGet = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/contact-support/get`;
  return axios.post(url, body);
};
api.supportDelete = function (body) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/contact-support/delete`;
  return axios.post(url, body);
};

api.checkSubscription = function (id) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/payment/check?id=${id}`;
  return axios.get(url);
};
api.getWallet = function (id) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/wallet/get?id=${id}`;
  return axios.get(url);
};

api.checkPortfolioExist = function (id) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/portfolio/exist?id=${id}`;
  return axios.get(url);
};
api.getWalletPortfolio = function (wallet) {
  const url = `${process.env.REACT_APP_SERVER_BASE_URL}/wallet/portfolio?wallet=${wallet}`;
  return axios.get(url);
};
api.addPortfolio = async (body) => {
  try {
    const data = await axios
      .post(`${process.env.REACT_APP_SERVER_BASE_URL}/portfolio/add`, body)
      .then((res) => {
        console.log(res);
        return res;
      });
    return data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
api.getPortfolio = async (userID) => {
  try {
    // console.log(userID);

    const data = await axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URL}/portfolio/get-new`, {
        id: userID,
      })
      .then((res) => {
        // console.log(res);
        return res;
      });
    return data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
api.getLatestQuotes = async () => {
  try {
    const data = await axios
      .get(`${process.env.REACT_APP_SERVER_BASE_URL}/portfolio/quotes/latest`)
      .then((res) => {
        // console.log(res);
        return res;
      });
    return data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
api.DeleteThisCoin = async (coin, user) => {
  try {
    const data = await axios
      .post(
        `${process.env.REACT_APP_SERVER_BASE_URL}/portfolio/delete-coin-tx-by-user`,
        {
          userID: user,
          coin,
        }
      )
      .then((res) => {
        // console.log(res);
        return res;
      });
    return data;
  } catch (err) {
    console.log(err.response.data);
    return err.response.data;
  }
};
export default api;
