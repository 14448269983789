import React, { useEffect, useState } from "react";
import { Pagination, Stack } from "@mui/material";
import moment from "moment";
import api from "../../api";
import { Loader } from "../global/loader";
import { useDispatch, useSelector } from "react-redux";
import userAction from "../../redux/users/action";
import { useAlert } from "react-alert";
import axios from "axios";

export const PayHistoryUser = () => {
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const alert = useAlert();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfData, setnoOfData] = useState(0);
  const [loader, setLoader] = useState(false);

  const getUsers = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      let checkSession = await checkUserSession();
      if (checkSession) {
        const userData = await api.getUserPayHistory(payload);
        // console.log(userData);
        setData(userData.post);
        setnoOfData(userData.count);
        setLimit(userData.limit);
        setLoader(false);
      } else {
        alert.show("Session Expired");
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getUsers();
  }, [page]);

  const checkUserSession = async () => {
    let token = localStorage.getItem("token");
    // console.log(token);
    if (token) {
      let payload = { token: token };
      const registerationResponse = await api.verifySession(payload);
      // console.log(registerationResponse);
      if (registerationResponse?.token) {
        axios.defaults.headers.common["Authorization"] =
          registerationResponse?.token;
        return true;
      } else {
        dispatch(setUser({}));
        localStorage.removeItem("token");
        return false;
      }
    }
  };

  return (
    <div className="payHistoryPage">
      <div className="container">
        <h2 className="text-center mt-4">Subscription History</h2>
        <Table
          data={data}
          setPage={setPage}
          page={page}
          noOfData={noOfData}
          limit={limit}
        />
      </div>
      {loader && <Loader />}
    </div>
  );
};
const Table = ({ data, page, setPage, noOfUser, limit }) => {
  const userStore = useSelector((state) => state.userReducer);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const formatDate = (date) => {
    let dateTemp = moment(date).format("MMMM Do YYYY, h:mm:ss a");
    return dateTemp;
  };

  return (
    <>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">
                    Email
                  </th>
                  <th scope="col" className="">
                    Amount
                  </th>
                  <th scope="col" className="">
                    Type
                  </th>
                  <th scope="col" className="">
                    Paid At
                  </th>
                  {/* <th scope="col" className="">
                    Subscription End
                  </th> */}
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <div className="table-text-dark w-max-c db">
                        {userStore?.users?.user?.email}
                      </div>
                    </td>
                    <td scope="row">
                      <div className="table-text-dark w-max-c db">
                        {item?.amountPaid}$
                      </div>
                    </td>
                    <td scope="row">
                      <div className="table-text-dark w-max-c db">
                        {item?.type == "1" ? "Monthly" : "Yearly"}
                      </div>
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        {formatDate(item?.createdAt)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(noOfUser / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};
